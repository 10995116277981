import store from '../store';
import * as loginTypes from '../types/logintypes';
import API from "../../services/api";
import Utils from '../../utils/utils';

const dispatch = store.dispatch;

export const logIn = async (payload) => {
	dispatch({ type: loginTypes.ON_LOG_IN, payload: { loading: true } });
	await API.post(Utils.getAPIDescription('log-in'), payload)
		.then((res) => {
			console.log(res.data);
			dispatch({ type: loginTypes.ON_LOG_IN_SUCCESS, payload: res });
		})
		.catch((err) => {
			console.log(err);
			dispatch({ type: loginTypes.ON_LOG_IN_FAILURE, payload: err });
		});
	dispatch({ type: loginTypes.ON_LOG_IN, payload: { loading: false } });
}

export const adminLogIn = async (payload) => {
	dispatch({ type: loginTypes.ON_LOG_IN, payload: { loading: true } });
	await API.post(Utils.getAPIDescription('admin-log-in'), payload)
		.then((res) => {
			console.log(res.data);
			dispatch({ type: loginTypes.ON_LOG_IN_SUCCESS, payload: res });
		})
		.catch((err) => {
			console.log(err);
			dispatch({ type: loginTypes.ON_LOG_IN_FAILURE, payload: err });
		});
	dispatch({ type: loginTypes.ON_LOG_IN, payload: { loading: false } });
}

export const deleteLoginState = () => {
	dispatch({ type: loginTypes.ON_DELETE_LOGIN_STATE, payload: {} });
}

export const openLoginSignUp = () => {
	dispatch({ type: loginTypes.ON_LOGIN_SIGNUP, payload: true });
}