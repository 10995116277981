import { useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import logo from "../../../../assets/logos/logo.png";
import { useLocation, useNavigate } from "react-router-dom"

import useAuth from '../../../../customhooks/admin/useauth';
import Profile from '../../profile';
import useInfo from '../../../../customhooks/admin/useinfo';
import { activeRoutes } from '../../../../routing';
import HideImageOutlinedIcon from '@mui/icons-material/HideImageOutlined';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import { mobileCheck } from '../../../../utils/common';


const drawerWidth = 280;
const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    overFlowY: 'hidden'
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function AdminToolBar() {
    const location = useLocation();
    const navigate = useNavigate();
    const auth = useAuth();
    const theme = useTheme();
    const [open, setOpen] = useState(true);
    const [pathName, setPathName] = useState('');
    const adminInfo = useInfo();
    const [openMenu, setOpenMenu] = useState({});
    const [isMobile, setIsMobile] = useState(false);
    
    useEffect(() => {
        const tmep = mobileCheck();
        setIsMobile(tmep);
        if (tmep) {
            setOpen(false);
        }
    }, []);

    useEffect(() => {
        setPathName(location.pathname);
        if (!auth) {
            navigate('/admin/login');
        }
    }, [location.pathname, auth, navigate])

    const handleDrawerOpen = () => {
        setOpen(true);
        setOpenMenu({});
    };

    const handleDrawerClose = () => {
        setOpen(false);
        setOpenMenu({});
    };

    const onItemClick = (item, idx) => {
        if (item.children) {
            openDropdown(idx);
            return;
        }
        navigate(item.path);
        if (isMobile) {
            setOpenMenu({});
        }
    }

    const openDropdown = (idx) => {
        let tmp = {
            ...openMenu,
            [idx]: !openMenu[idx]
        };
        setOpenMenu(tmp);
    }

    const getMenuItem = (item, index) => {
        
        return (
            <>
                <ListItem key={item.text} disablePadding sx={{ display: 'block' }} onClick={() => onItemClick(item, index)} className={`${pathName === item.path && 'selected'}`}>
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            {item.icon ? <item.icon /> : <HideImageOutlinedIcon />}
                        </ListItemIcon>
                        <ListItemText primary={item.text} sx={{ opacity: open ? 1 : 0 }} />
                        {item.children && open && (openMenu[index] === true ? <ExpandLess /> : <ExpandMore />)}
                    </ListItemButton>
                </ListItem>
                {item.children &&
                    <Collapse in={openMenu[index] === true} timeout="auto" unmountOnExit sx={{ pl: 4 }} className={!open && 'nested-menu-option'}>
                        <List component="div" disablePadding>
                            {item.children.map((cItem, cIdx) => getMenuItem(cItem, `${index}_${cIdx}`))}
                        </List>
                    </Collapse>
                }
            </>
        )
    }

    return (
        <>
            {
                auth &&

                <Box className="admin-toolbar" sx={{ 
                    display: pathName.indexOf('admin/login') === -1 ? 'flex' : 'none',
                 }}>
                    <CssBaseline />
                    <AppBar position="fixed" open={open}>
                        <Toolbar>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                className="do-btn"
                                sx={{
                                    marginRight: 5,
                                    ...(open && { display: 'none' }),
                                }}
                            >

                                <MenuIcon />
                            </IconButton>
                            {auth &&
                                <Box sx={{position: 'absolute', right: '30px'}}>
                                    <Profile adminInfo={adminInfo} />
                                </Box>
                            }
                        </Toolbar>
                    </AppBar>
                    <Drawer variant="permanent" open={open}>
                        <DrawerHeader className='space-between' sx={{backgroundColor: '#ffffff'}}>
                            <img src={logo} alt="treatu logo" className="admin-logo" />
                            <IconButton onClick={handleDrawerClose}>
                                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                            </IconButton>
                        </DrawerHeader>
                        <Divider />
                        <List>
                            {activeRoutes
                            .filter(item => item.isAdmin === true && item.hidden !== true)
                            .map((item, index) => getMenuItem(item, index))}
                        </List>
                    </Drawer>
                </Box>
            }
        </>
    );
}
