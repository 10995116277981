import { EncryptStorage } from 'encrypt-storage';

EncryptStorage.prototype.oldGetItem = EncryptStorage.prototype.getItem;

EncryptStorage.prototype.getItem = function(t) {
    try {
        return this.oldGetItem(t);
    } catch(e) {
        localStorage.clear();
        window.location.reload();
    }
}

export const encryptStorage = new EncryptStorage('7Hbfh9oGc912pHTXCMdwBfDx13EZmr3R');