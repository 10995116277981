import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import API from '../../services/api';
import environment from "../../utils/environment";
import { apiResponseHandler } from "../../utils/utils";
import OtpInput from "otp-input-react";
import { auth } from "../../config/firebase.config";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { toast, Toaster } from "react-hot-toast";
import { CgSpinner } from "react-icons/cg";
import swal from 'sweetalert2';



export default function ForgetPassword(props) {
    const { open, setOpen } = props;
    const [userInputs, setUserInputs] = useState({});
    const [otpStatus, setOtpStatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [otp, setOtp] = useState("");

    const serUserInfo = (field, value) => {
        setUserInputs({
            ...userInputs,
            [field]: value
        });
    }

    const verifyCaptcha = () => {
        if (!window.recaptchaVerifier) {
            window.recaptchaVerifier = new RecaptchaVerifier(
                "recaptcha-container",
                {
                    size: "invisible",
                    callback: (response) => {},
                    "expired-callback": () => { },
                },
                auth
            );
        }
    }

    useEffect(() => {
        if (open) {
            setUserInputs({});
            setOtpStatus('');
            setLoading(false);
            setOtp('');
        }
    }, [open])


    const sendOTPMessage = (info) => {
        setLoading(true);
        verifyCaptcha();
        const appVerifier = window.recaptchaVerifier;
        const formatPh = "+" + info.mobileNumber;
        signInWithPhoneNumber(auth, formatPh, appVerifier)
            .then((confirmationResult) => {
                window.confirmationResult = confirmationResult;
                setLoading(false);
                setOtpStatus('sent');
                toast.success("OTP sent successfully!");
                setOtp("");
            })
            .catch((error) => {
                toast.error(error?.message);
                setLoading(false);
            });
    }

    const sendOtpToPhone = async () => {
        try {
            setLoading(true);
            const userInfo = await API.post(`${environment.verify_email_id}`, userInputs).then(apiResponseHandler);
            if (userInfo) {
                setLoading(false);
                sendOTPMessage(userInfo)
            }
        } catch (e) {
            toast.error("Invalid Email Id");
            setLoading(false);
        }
    }

    function onOTPVerify() {
        window.confirmationResult
            .confirm(otp)
            .then(async (res) => {
                setOtpStatus('verified')
                // setSignupValue('mobileNumber', res.user.phoneNumber);
                toast.success("Mobile number verified successfully!");
            })
            .catch((err) => {
                toast.error(err.message);
            });
    }

    const updatePassword = async () => {
        try {
            await API.post(`${environment.update_user_password}`, userInputs).then(apiResponseHandler);
            swal.fire('Success!', 'Password updated successfully!', 'success');
            handleClose();
        } catch(e) {
            toast.error("Something went wrong");
        }
    }

    const handleClose = () => setOpen(false);

    return (
        <Dialog open={open} maxWidth="xs" fullWidth>
            <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ flex: 1 }}>
                    <Typography className="brown-md-txt" sx={{ textAlign: "center" }}>
                        {otpStatus === 'verified' ? 'Set New Password' : 'Forget Password'}
                    </Typography>
                </div>
                <IconButton onClick={handleClose}>
                    <CloseOutlinedIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Toaster toastOptions={{ duration: 4000 }} />
                <Grid container className="login-grid" spacing={3} sx={{ padding: '10px' }}>
                    <Grid item xs="12">
                        <TextField
                            label="Email Id"
                            type='email'
                            variant="outlined"
                            size="small"
                            fullWidth
                            // onKeyDown={handleEnterLogin}
                            value={userInputs.email || ''}
                            onChange={e => serUserInfo('email', e.target.value)}
                            disabled={otpStatus === 'verified'}
                        />
                        {otpStatus !== 'verified' &&
                            <Box sx={{textAlign: 'right'}}>
                                <Button sx={{mt: 1}} variant="contained" size="small" color="secondary" onClick={sendOtpToPhone} disabled={!userInputs.email}>
                                    {loading && (
                                        <CgSpinner size={20} className="mt-1 animate-spin" />
                                    )}
                                    {otpStatus === 'sent' ? 'Re-send OTP' : 'Send OTP'}
                                </Button>
                            </Box>
                        }
                    </Grid>
                    {otpStatus === 'sent' &&
                        <>
                            <Grid item xs="9">
                                <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    OTPLength={6}
                                    otpType="number"
                                    disabled={false}
                                    autoFocus
                                    className="otp-input"
                                ></OtpInput>
                                <Box display={'flex'} justifyContent={'center'}>
                                    {/* <Box><p style={{ fontSize: '0.75rem', color: '#d32f2f', marginBottom: '0px', marginTop: '5px' }}>{otpMsg}</p></Box>
                                    <Button variant='text' onClick={() => setShowOTP(false)}>Cancel</Button> */}
                                </Box>
                            </Grid>
                            <Grid item xs="3">
                                <Button fullWidth variant="outlined" color="secondary"
                                    disabled={otp.length !== 6}
                                    onClick={onOTPVerify}
                                    sx={{ height: '38px' }}
                                >
                                    {loading && (
                                        <CgSpinner size={20} className="mt-1 animate-spin" />
                                    )}
                                    <span>Verify</span>
                                </Button>
                            </Grid>
                        </>
                    }
                    {otpStatus === 'verified' && 
                        <>
                            <Grid item xs="12">
                                <TextField
                                    name="Outlined"
                                    label="New Password"
                                    type='password'
                                    variant="outlined"
                                    size="small"
                                    autoComplete='off'
                                    fullWidth
                                    // onKeyDown={handleEnterLogin}
                                    value={userInputs.password || ''}
                                    onChange={e => serUserInfo('password', e.target.value)}
                                    helperText={'Password must be at least 6 characters long.'}
                                />
                            </Grid>
                            <Grid item xs="12">
                                <TextField
                                    name="Outlined"
                                    label="Confirm Password"
                                    type='password'
                                    variant="outlined"
                                    size="small"
                                    autoComplete='off'
                                    fullWidth
                                    // onKeyDown={handleEnterLogin}
                                    value={userInputs.confirm_password || ''}
                                    onChange={e => serUserInfo('confirm_password', e.target.value)}
                                    helperText="Password & Confirm Password must be same"
                                />
                            </Grid>
                        </>
                    }
                </Grid>
            </DialogContent>
            {otpStatus === 'verified' && 
                <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Grid container spacing={3} sx={{ padding: '0px 25px' }}>
                        <Grid item xs="12">
                            <Button 
                                fullWidth 
                                variant="contained"
                                color="secondary"
                                disabled={userInputs?.password?.length < 6 || userInputs?.password !== userInputs?.confirm_password}
                                onClick={updatePassword}
                            >
                                Update Password
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            }
        </Dialog>
    )
}