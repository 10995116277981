import React from 'react';


// Icons import
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import TickerOutlinedIcon from '@mui/icons-material/MoneyOutlined';
import AlgoIcon from '@mui/icons-material/MemoryOutlined';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';


const Home = React.lazy(() => import('./pages/user/home/home'));
const AboutUs = React.lazy(() => import('./pages/user/aboutus/'));
const ContactUs = React.lazy(() => import('./pages/user/contactus/'));
const Stocks = React.lazy(() => import('./pages/user/stocks/'));
const Watchlist = React.lazy(() => import('./pages/user/watchlist/watchlist'));

const Profile = React.lazy(() => import('./pages/user/profile/profile'));
const OurPartners = React.lazy(() => import('./pages/user/ourpartners/ourpartners'));
//const Search = React.lazy(() => import('./pages/user/search/search'));


// Admin pages import
const AdminLogin = React.lazy(() => import('./pages/admin/adminlogin/adminlogin'));
const Dashboard = React.lazy(() => import('./pages/admin/dashboard/dashboard'));
const AdminUserList = React.lazy(() => import('./pages/admin/userlist/userslist'));
const TickersList = React.lazy(() => import('./pages/admin/tickers/tickers'));
const AlgoCallList = React.lazy(() => import('./pages/admin/algocalls/algocalls'));
const SettingsComponent = React.lazy(() => import('./pages/admin/settings/settings'));

export const userRoutes = [{
    path: '/',
    text: 'Home',
    component: Home
},
{
    path: '/aboutus',
    text: 'About Us',
    component: AboutUs
},
{
    path: '/about',
    text: 'About Us',
    component: AboutUs
},
{
    path: '/partnerwithus',
    text: 'Our Partners',
    component: OurPartners
},
{
    path: '/tickers',
    text: 'Market Analysis',
    component: Stocks
},
{
    path: '/tickers/:exchangeShortName',
    text: 'Market Analysis :exchangeShortName',
    component: Stocks
},
{
    path: '/tickers/:exchangeShortName/:symbol',
    text: 'Market Analysis :exchangeShortName :symbol',
    component: Stocks
},
{
    path: '/tickers/:exchangeShortName/:symbol/:algo',
    text: 'Market Analysis :exchangeShortName :symbol :algo',
    component: Stocks
},
{
    path: '/contact',
    text: 'Contact Us',
    component: ContactUs
},
{
    path: '/profile',
    text: 'Profile',
    component: Profile
},
{
    path: '/watchlist',
    text: 'My Watchlist',
    component: Watchlist
}

];

export const adminRoutes = [
    {
        path: '/admin/login',
        text: 'Login',
        isAdmin: true,
        hidden: true,
        component: AdminLogin
    },
    {
        path: '/admin',
        text: 'Dashboard',
        isAdmin: true,
        icon: DashboardIcon,
        component: Dashboard
    },
    {
        path: '/admin/settings',
        text: 'Settings',
        icon: SettingsIcon,
        isAdmin: true,
        component: SettingsComponent,
    },
    {
        path: '/admin/users',
        text: 'Users',
        icon: PeopleOutlinedIcon,
        isAdmin: true,
        component: AdminUserList,
    },
    {
        path: '/admin/tickers',
        text: 'Tickers',
        icon: TickerOutlinedIcon,
        isAdmin: true,
        component: TickersList,
    },
    {
        path: '/admin/algocalls',
        text: 'Algo Calls',
        icon: AlgoIcon,
        isAdmin: true,
        component: AlgoCallList,
    },
];

export const allRoutes = [...userRoutes, ...adminRoutes];

function getFlatComponent() {
    let temp = [];
    for (let path of allRoutes) {
        temp.push(path);
        if (path.children) {
            temp.push(path.children);
        }
    }
    return temp.flat(Infinity).filter(itm => itm.path !== undefined && itm.component !== undefined);
}
export const activeRoutes = getFlatComponent();
export default allRoutes;