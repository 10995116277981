// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Import the functions you need from the SDKs you need

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBiE__Vpto2S-K8DGs8-fMP1tnIFeyzjlY",
  authDomain: "pricealgo-92dee.firebaseapp.com",
  projectId: "pricealgo-92dee",
  storageBucket: "pricealgo-92dee.appspot.com",
  messagingSenderId: "820349053065",
  appId: "1:820349053065:web:61e94e5eaec1f5e963fe58",
  measurementId: "G-N1PE7800TS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
console.log(analytics);
export const auth = getAuth(app);