import * as stocktypes from '../types/stocktypes';

const initialState = {
    list: {},
    loading: false,
    history: [],
    historyLoading: false,
    stockHistory: [],
    stockHistoryLoading: false
};

export default function stockReducer(state = initialState, action) {
    switch (action.type) {
        case stocktypes.ON_STOCK_LOAD_SUCCESS:
            return {
                ...state,
                list: {
                    ...state.list,
                    [action.payload.name]: action.payload
                },
                loading: action.payload.loading
            };

        case stocktypes.ON_ALL_STOCK_LOAD_SUCCESS:
            const result = action.payload.reduce((acc, curr) => {
                acc[curr.stockName] = curr.data;
                return acc;
            }, {});
            return {
                ...state,
                list: {
                    ...state.list,
                    ...result
                },
                stockHistoryLoading: action.payload.loading
            };

        case stocktypes.ON_ALL_STOCK_HISTORY_SUCCESS:
            const stockResult = action.payload.reduce((acc, curr) => {
                acc[curr.stockName] = curr.data;
                return acc;
            }, {});
            return {
                ...state,
                stockHistory: {
                    ...state.list,
                    ...stockResult
                }
            };

        case stocktypes.ON_STOCK_LOAD_FAILURE:
            return {
                ...state,
                result: action.payload.data
            };

        case stocktypes.FETCH_SEARCH_HISTORY_REQUEST:
            return { ...state, historyLoading: true };

        case stocktypes.FETCH_SEARCH_HISTORY_SUCCESS:
            return { historyLoading: false, history: action.payload, error: null };

        case stocktypes.FETCH_SEARCH_HISTORY_FAILURE:
            return { historyLoading: false, history: [], error: action.payload };

        default:
            return state;
    }
}