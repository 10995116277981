import { applyMiddleware, compose, legacy_createStore as createStore, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import loginReducer from './reducers/loginreducer';
import signupReducer from './reducers/signupreducer';
import profileReducer from './reducers/profilereducer';
import stockReducer from './reducers/stockreducer';

const combinedReducer = combineReducers({
    signup: signupReducer,
    login: loginReducer,
    profile: profileReducer,
    stocks: stockReducer,
})

function getStore(reducers) {
    const composeEnhancers =
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const store = createStore(
        reducers,
        composeEnhancers(applyMiddleware(thunk))
    );
    return store;
}

const store = getStore(combinedReducer);
export default store;