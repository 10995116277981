import axios from "axios";
import store from "../../redux/store";
import Swal from "sweetalert2";
import Utils from "../../utils/utils";
import { encryptStorage } from "../../utils/storage";

const getAuthToken = () => {
    let authToken;
    const userType = Utils.getUserType();
    if (userType === 'admin') {
        let adminInfo = encryptStorage.getItem("admin-info");
        authToken = adminInfo?.token;
    } else {
        authToken = encryptStorage.getItem("user-token");
    }
    return authToken;
}

const API = () => {    
    const defaultOptions = {
        maxBodyLength: Infinity,
        maxContentLength: Infinity,
        headers: {
            // "Content-Type": "application/json",
            /* "Access-Contol-Allow-Credentials": "true",*/
            /* "Access-Control-Allow-Origin": "*",*/
            "authorization": getAuthToken(),
            // "Access-Control-Allow-Methods": "GET, POST, PATCH, DELETE, PUT, OPTIONS",
            /*"Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With"*/
        }
    }

    //Creating Axios Instance
    let instance = axios.create(defaultOptions);

    //Setting auth token for each request using this axios interceptor
    instance.interceptors.request.use(function (config) {
        if (config?.showSpinner) {
            store.dispatch({ typr: "SHOW_SPINNER" });
        }
        config.headers.authorization = getAuthToken();
        return config;
    });

    instance.interceptors.response.use(
        (response) => {
            const { config } = response;
            if (config?.showSpinner) {
                store.dispatch({ type: "HIDE_SPINNER" })
            }

            return response;
        },
        (error) => {
            const userType = Utils.getUserType();
            const { config } = error;
            if (config?.showSpinner) {
                store.dispatch({ type: "HIDE_SPINNER" });
            }

            if (error.response && error.response.status === 401) {
                Swal.fire('Session Expired..., redirecting to Login...', '', 'error');
                encryptStorage.clear();
                if(userType === 'admin'){
                    setTimeout(() => {window.location.href = '/admin/login';}, 3000);
                }else{
                    setTimeout(() => {window.location.href = '/';}, 3000);
                }
               
              
            } else {
                console.log("Something went wrong!");
            }
            return Promise.reject(error);
        }
    )

    return instance;
}

export default API();