import { useEffect, useState } from 'react';
import '../../assets/js/offline.min.js';
import WifiOffIcon from '@mui/icons-material/WifiOff';

import './offline.scss';
import { Box, Typography } from '@mui/material';

export default function NetworkValidator() {
    const [isOffline, setIsOffline] = useState(false);

    useEffect(() => {
        const Offline = window.Offline;
        Offline.on('confirmed-down', function () {
            setIsOffline(true);
        });

        Offline.on('confirmed-up', function () {
            setIsOffline(false);
        });
    });
    
    
    return isOffline ? (
        <div className='offlineDiv'>
            <div className='notification-div'>
                <WifiOffIcon className="animate__animated animate__infinite animate__slow animate__flash" sx={{ fontSize: '15em', color: '#ffffff' }} />
                <Box sx={{color: '#ffffff'}}>
                    <Typography variant='h1'>You are offline.</Typography>
                    <Typography variant='h4'>Please check your internet connection.</Typography>
                </Box>
            </div>
        </div>
    ) : null;
}