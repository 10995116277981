import { useEffect, Suspense, useState } from 'react';
import './App.css';
import './assets/animate.min.css';
import { Routes, Route, useLocation } from 'react-router-dom';

import Navbar from './shared/components/navigation/navbar/navbar';
import Footer from './shared/components/footer/footer';
import LoadingComponent from './components/loading';
import { activeRoutes } from './routing';
import NetworkValidator from './components/offline';

import './pages/user/home/home.scss';
import ForgetPassword from './components/login/forgetPassword';

function App() {
	const [isAdminPage, setIsAdminPage] = useState(false);
	const location = useLocation();

	useEffect(() => {
		// Correctly checking the current path for 'admin'
		setIsAdminPage(location.pathname.includes('admin'));
	}, [location.pathname]);
	
	return (
		<div className={`App ${isAdminPage ? 'flex-box' : ''}`}>
			<NetworkValidator />
			<Navbar />
			<Suspense fallback={<LoadingComponent />}>
				<Routes>
					{activeRoutes.map(route => (
						<Route key={route.path} path={route.path} element={<route.component />} />
					))}
				</Routes>
			</Suspense>
			{!isAdminPage && <Footer />}
			<ForgetPassword />
		</div>
	);
}

export default App;
